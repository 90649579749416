
import { defineComponent, reactive, toRaw } from 'vue';
import { useForm } from 'ant-design-vue/es/form';
import { useStore } from 'vuex';
import ReceiverAccount, { ReceiverAccountType } from '../receiver-account.vue';
import { FormState } from '../../model';

export default defineComponent({
  emits: ['next-step'],
  setup(_, { emit }) {
    const store = useStore();

    const savedStepForm: FormState = toRaw(store.getters['stepForm/step']);

    const modelRef = reactive({
      payAccount: savedStepForm.payAccount,
      receiverAccount: {
        ...savedStepForm.receiverAccount,
      },
      receiverName: savedStepForm.receiverName,
      amount: savedStepForm.amount,
    });

    const receiverAccount = (rule: any, value: ReceiverAccountType) => {
      return new Promise<string | void>((resolve, reject) => {
        const checkValue = toRaw(value);
        if (checkValue && checkValue.number) {
          resolve();
        } else {
          reject('请输入收款账号');
        }
      });
    };

    const rulesRef = reactive({
      payAccount: [{ required: true, message: '请选择付款账户' }],
      receiverAccount: [
        {
          validator: receiverAccount,
          trigger: 'change',
        },
      ],
      receiverName: [{ required: true, message: '请输入收款人姓名' }],
      amount: [
        {
          required: true,
          message: '请输入转账金额',
        },
        {
          pattern: /^(\d+)((?:\.\d+)?)$/,
          message: '请输入合法金额数字',
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();

      validate()
        .then(() => {
          store.dispatch('stepForm/saveStepFormData', toRaw(modelRef)).then(() => {
            emit('next-step');
          });

          // store.commit({
          //   type: 'stepForm/saveStepFormData',
          //   payload: toRaw(modelRef),
          // });
        })
        .catch(err => {
          console.error('err', err);
          console.warn('validateInfos', toRaw(validateInfos));
        });
    };
    return {
      modelRef,
      validateInfos,
      resetFields,
      handleSubmit,
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 10 }, sm: { span: 17 } },
    };
  },
  components: {
    ReceiverAccount,
  },
});
